// import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { createRouter, createWebHistory } from 'vue-router';
import '@/assets/css/app.css';
import VerifyRegistration from '@/components/DDVerifyRegistration.vue';
import DDLoginWithTempPassword from "@/components/DDLoginWithTempPassword.vue";
import Home from '@/components/DDHomeInfo.vue';
import Dashboard from "@/components/DDDashboard.vue";
import DDFilesTab from "@/components/DDFilesTab.vue";
import DDUploadTab from "@/components/DDUploadTab.vue";
import DDApiTab from "@/components/DDApiTab.vue";
import DDSchoolsTab from "@/components/DDSchoolsTab.vue";
import DDUsersTab from "@/components/DDUsersTab.vue";
import DDEmailsTab from "@/components/DDEmailsTab.vue";
import DDDocumentTab from "@/components/DDDocumentTab.vue";
import DDProfile from "@/components/DDProfile.vue";
import DDForgetPassword from "@/components/DDForgetPassword.vue";
import DDPrivacyNotice from "@/components/DDPrivacyNotice.vue";
import DDTermOfUse from "@/components/DDTermOfUse.vue";
import DDCookieNotice from "@/components/DDCookieNotice.vue";
import LoginPage from "@/pages/LoginPage.vue";
import plugins from "@/plugins";
import DashboardPage from "@/pages/DashboardPage.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import UploadPage from "@/pages/UploadPage.vue";
import EligibilityCheckPage from "@/pages/EligibilityCheckPage.vue";
import UsersPage from "@/pages/UsersPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import SchoolsPage from "@/pages/SchoolsPage.vue";
import VerifyRegistrationPage from "@/pages/VerifyRegistrationPage.vue";


window.Vapor = require('laravel-vapor');


const routes = [
    {path: '/login', component: LoginPage, name: 'login'},
    {path: '/verify_registration', component: VerifyRegistrationPage, name: 'Verify Registration'},
    {path: '/verify_registration2', component: VerifyRegistration, name: 'Verify Registration2'},
    {path: '/login-temp-password', component: DDLoginWithTempPassword, name: 'Login With Temp Password'},
    {path: '/forgot-password', component: DDForgetPassword, name: 'Forget Password'},
    {path: '/profile', component: DDProfile, name: 'profile'},
    {
        path: '/', component: AuthenticatedLayout, meta: { requiresAuth: true },
        children: [
            {
                path: 'dashboard',
                component: DashboardPage,
                name: 'dashboard',
                meta: { requiresAuth: true }
            },
            {
                path: 'schools2',
                component: DDSchoolsTab,
                name: 'schools2',
                meta: { requiresAuth: true }
            },
            {
                path: 'schools',
                component: SchoolsPage,
                name: 'schools',
                meta: { requiresAuth: true }
            },
            {
                path: 'users2',
                component: DDUsersTab,
                name: 'users2',
                meta: { requiresAuth: true }
            },
            {
                path: 'users',
                component: UsersPage,
                name: 'users',
                meta: { requiresAuth: true }
            },
            {
                path: 'upload2',
                component: DDUploadTab,
                name: 'upload2',
                meta: { requiresAuth: true }
            },
            {
                path: 'upload',
                component: UploadPage,
                name: 'upload',
                meta: { requiresAuth: true }
            },
            {
                path: 'eligibility-check2',
                component: DDApiTab,
                name: 'eligibility-check2',
                meta: {requiresAuth: true}
            },
            {
                path: 'eligibility-check',
                component: EligibilityCheckPage,
                name: 'eligibility-check',
                meta: {requiresAuth: true}
            },
            {
                path: 'docs',
                component: DDDocumentTab,
                name: 'docs',
                meta: { requiresAuth: true }
            },
            {
                path: 'profile',
                component: ProfilePage,
                name: 'profile',
                meta: { requiresAuth: true }
            },
            {
                path: '/privacy-notice',
                component: DDPrivacyNotice,
                name: 'Privacy Notice'
            },
            {
                path: '/terms-of-use',
                component: DDTermOfUse,
                name: 'Term Of Use'
            },
            {
                path: '/cookie-notice',
                component: DDCookieNotice,
                name: 'Cookie Notice'
            },
            {
                path: '/:catchAll(.*)',
                redirect: { name: 'dashboard' },

            },
        ]
    },
    {
        path: '/dashboard2', component: Dashboard, name: 'dashboard2', meta: { requiresAuth: true },
        children: [
            {
                path: 'files',
                component: DDFilesTab,
                name: 'files',
                meta: { requiresAuth: true }
            },
            {
                path: 'emails',
                component: DDEmailsTab,
                name: 'emails',
                meta: { requiresAuth: true }
            },
            {
                path: '/:catchAll(.*)',
                component: Home,
                name: 'home',
            },
        ],
    },
    {
        path: '/public/privacy-notice',
        component: DDPrivacyNotice,
        name: 'Public Privacy Notice'
    },
    {
        path: '/public/unauthenticated/terms-of-use',
        component: DDTermOfUse,
        name: 'Public Term Of Use'
    },
    {
        path: '/public/unauthenticated/cookie-notice',
        component: DDCookieNotice,
        name: 'Public Cookie Notice'
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in, token should be in vuex store
        // if not, redirect to login page.
        const token = store.getters.userToken;
        if (!token) {
            next(
                {
                    name: 'login',
                });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

const app = createApp(App);
plugins(app);
app.use(router);
app.use(store);
app.mount('#app');
