<template>
    <div class="flex flex-col min-h-screen mb-3">
        <!-- Main Content Area -->
        <main class="flex-1 p-6">
          <div class="flex items-start p-4 space-x-4">
            <router-link v-if="showFilesTab" to="/dashboard/files" class="tab-link flex flex-col items-center space-y-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-6">
                <path
                    d="M320 464c8.8 0 16-7.2 16-16l0-288-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16l256 0zM0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 448c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64z"/>
              </svg>
              <span>FILES</span>
            </router-link>
            <router-link v-if="showSchoolsTab" to="/dashboard/schools" class="tab-link flex flex-col items-center space-y-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-6 h-6">
                <path d="M337.8 5.4C327-1.8 313-1.8 302.2 5.4L166.3 96 48 96C21.5 96 0 117.5 0 144L0 464c0 26.5 21.5 48 48 48l208 0 0-96c0-35.3 28.7-64 64-64s64 28.7 64 64l0 96 208 0c26.5 0 48-21.5 48-48l0-320c0-26.5-21.5-48-48-48L473.7 96 337.8 5.4zM96 192l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64c0-8.8 7.2-16 16-16zm400 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64zM96 320l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64c0-8.8 7.2-16 16-16zm400 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64zM232 176a88 88 0 1 1 176 0 88 88 0 1 1 -176 0zm88-48c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-16 0 0-16c0-8.8-7.2-16-16-16z"/>
              </svg>
              <span>SCHOOLS</span>
            </router-link>
            <router-link v-if="showUsersTab" to="/dashboard/users" class="tab-link flex flex-col items-center space-y-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-6 h-6">
                <path d="M211.2 96a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zM32 256c0 17.7 14.3 32 32 32l85.6 0c10.1-39.4 38.6-71.5 75.8-86.6c-9.7-6-21.2-9.4-33.4-9.4l-96 0c-35.3 0-64 28.7-64 64zm461.6 32l82.4 0c17.7 0 32-14.3 32-32c0-35.3-28.7-64-64-64l-96 0c-11.7 0-22.7 3.1-32.1 8.6c38.1 14.8 67.4 47.3 77.7 87.4zM391.2 226.4c-6.9-1.6-14.2-2.4-21.6-2.4l-96 0c-8.5 0-16.7 1.1-24.5 3.1c-30.8 8.1-55.6 31.1-66.1 60.9c-3.5 10-5.5 20.8-5.5 32c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32c0-11.2-1.9-22-5.5-32c-10.8-30.7-36.8-54.2-68.9-61.6zM563.2 96a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zM321.6 192a80 80 0 1 0 0-160 80 80 0 1 0 0 160zM32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32l576 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 416z"/>
              </svg>
              <span>USERS</span>
            </router-link>
            <router-link v-if="showUploadTab" to="/dashboard/upload" class="tab-link flex flex-col items-center space-y-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-6 h-6">
                <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/>
              </svg>
              <span>UPLOAD</span>
            </router-link>
            <router-link v-if="showApiTab" to="/dashboard/eligibility-check" class="tab-link flex flex-col items-center space-y-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-6 h-6">
                <path d="M96 80c0-26.5 21.5-48 48-48l288 0c26.5 0 48 21.5 48 48l0 304L96 384 96 80zm313 47c-9.4-9.4-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L409 161c9.4-9.4 9.4-24.6 0-33.9zM0 336c0-26.5 21.5-48 48-48l16 0 0 128 448 0 0-128 16 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48L48 480c-26.5 0-48-21.5-48-48l0-96z"/>
              </svg>
              <span>ELIGIBILITY CHECK</span>
            </router-link>
            <router-link v-if="showEmailsTab" to="/dashboard/emails" class="tab-link flex flex-col items-center space-y-2">
              <span>Email</span>
            </router-link>
            <router-link v-if="showDocsTab" to="/dashboard/docs" class="tab-link flex flex-col items-center space-y-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-6 h-6">
                <path
                    d="M96 0C43 0 0 43 0 96L0 416c0 53 43 96 96 96l288 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L384 0 96 0zm0 384l256 0 0 64L96 448c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16zm16 48l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
              </svg>
              <span>DOCUMENT</span>
            </router-link>
          </div>
            <router-view :showFilesTab="showFilesTab" :showSchoolsTab="showSchoolsTab" :showUsersTab="showUsersTab"
                :showUploadTab="showUploadTab" :showApiTab="showApiTab" :showEmailsTab="showEmailsTab"
                :showDocsTab="showDocsTab"></router-view>
        </main>
    </div>
</template>

<style scoped>
.tab-link {
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    /* Adjust the text color */
    transition: color 0.3s, border-bottom-color 0.3s;
    border-bottom: 2px solid transparent;
}

.tab-link:not(:last-child) {
    margin-right: 20px;
    /* Adjust the spacing between tabs */
}

.tab-link:hover {
    color: #4285f4;
    /* Hover color */
}

/* Style for the active tab */
.tab-link.router-link-exact-active {
    color: #4285f4;
    /* Active tab color */
    border-bottom-color: #4285f4;
    /* Active tab underline color */
}
</style>

<script>
import tabService from "@/services/TabService";
import TokenService from "@/services/TokenService";

export default {
    name: 'DashboardLayout',
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_BASE_URL,
            showFilesTab: false,
            showSchoolsTab: false,
            showUsersTab: false,
            showUploadTab: false,
            showApiTab: false,
            showEmailsTab: false,
            showDocsTab: false,
        };
    },
    async mounted() {
        await this.checkUserPermissionsForTabs();
        if(this.$store.getters.userName !== null) {
          this.$emit('update-user-name', this.$store.getters.userName);
        }

        this.redirectUserToFirstAvailableTab();
    },
    methods: {
        navigateTo(path) {
            this.$router.push(path);
        },
        async checkUserPermissionsForTabs() {
            const config = TokenService.getConfig(this.$router, this.$store);
            if (!config) {
                return;
            }

            const tabs = await tabService.listUserTabPermissions(config);

            this.showFilesTab = tabs.includes('files');
            this.showSchoolsTab = tabs.includes('schools');
            this.showUsersTab = tabs.includes('users');
            this.showUploadTab = tabs.includes('upload');
            this.showApiTab = tabs.includes('api');
            this.showEmailsTab = tabs.includes('emails');
            this.showDocsTab = tabs.includes('docs');
        },
        async redirectUserToFirstAvailableTab() {
            const path = await tabService.getFirstAvailableTabUrl();

            const current = this.$route.path;

            if (current == path) {
                return false;
            }

            this.navigateTo(path)
        },
    },
};
</script>
