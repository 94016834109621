<template>
    <div v-if="showSchoolsTab" class="mb-16">
        <h2 class="text-3xl font-bold mb-4">School Information For Digital Doctor</h2>
        <table class="table w-full border-collapse border-b border-gray-300">
            <thead>
                <tr class="bg-gray-100">
                    <th class="border-b border-gray-300 px-4 py-2">ID</th>
                    <th class="border-b border-gray-300 px-4 py-2">Name</th>
                    <th class="border-b border-gray-300 px-4 py-2">Aliases</th>
                    <th class="border-b border-gray-300 px-4 py-2">Reporting Streams</th>
                    <th class="border-b border-gray-300 px-4 py-2">Contract</th>
                    <th class="border-b border-gray-300 px-4 py-2">Contract Start Date</th>
                    <th class="border-b border-gray-300 px-4 py-2">Contract End Date</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(school, index) in schools" :key="index" :class="{ 'bg-gray-50': index % 2 === 0 }">
                    <td class="border-b border-gray-300 px-4 py-2">{{ school.id }}</td>
                    <td class="border-b border-gray-300 px-4 py-2">
                        <ul>
                            <li class="mb-2">
                                {{ school.name }}
                            </li>
                        </ul>
                        <button @click="showSchoolCreationForm()" class="create-button">+</button>
                    </td>
                    <td class="border-b border-gray-300 px-4 py-2">
                        <ul class="mb-2">
                            <li v-for="(alias, i) in school.aliases" :key="i" @click="showAliasDetails(alias)">
                                {{ alias.alias }}
                            </li>
                        </ul>
                        <button @click="showAliasCreationForm(school.id)"
                            class="create-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">+</button>
                    </td>
                    <td class="border-b border-gray-300 px-4 py-2">
                        <ul class="mb-2">
                            <li v-for="(reportingStream, i) in school.reporting_streams" :key="i"
                                @click="showReportingStreamDetails(reportingStream)">
                                {{ reportingStream.name }}
                            </li>
                        </ul>
                        <button @click="showReportingStreamCreationForm(school.id)"
                            class="create-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">+</button>
                    </td>
                    <td class="border-gray-300 px-4 py-2">
                        <div>
                            <ul>
                                <li v-for="(contract, i) in school.contracts" :key="i"
                                    @click="showContractDetails(contract)">
                                    {{ contract.contract_number }}
                                </li>
                            </ul>
                            <button v-if="!school.contracts.length" @click="showContractCreationForm(school.id)"
                                class="create-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">+</button>
                        </div>
                    </td>
                    <td class="border-gray-300 px-4 py-2">
                        <ul>
                            <li v-for="(contract, i) in school.contracts" :key="i" style="{cursor: pointer;}"
                                @click="showContractDetails(contract)">
                                {{ contract.term_start_date }}
                            </li>
                        </ul>
                    </td>

                    <td class="border-gray-300 px-4 py-2">
                        <ul>
                            <li v-for="(contract, i) in school.contracts" :key="i" style="{cursor: pointer;}"
                                @click="showContractDetails(contract)">
                                {{ contract.term_end_date }}
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>


        <div v-if="selectedDetail" class="bg-white p-6 rounded-md shadow-md max-w-md mx-auto mt-4">
            <h3 class="text-xl font-bold mb-4">
                Detail
            </h3>

            <!-- Display detailed information based on the selected detail type -->
            <table class="w-full mb-4">
                <tbody>
                    <template v-if="selectedDetailType === 'aliases'">
                        <tr>
                            <th class="py-2 float-left">Alias Name</th>
                            <td class="py-2 float-right">{{ selectedDetail.alias }}</td>
                        </tr>
                    </template>
                    <template v-if="selectedDetailType === 'reporting_streams'">
                        <tr>
                            <th class="py-2 float-left" style="float: left;">Reporting Stream</th>
                            <td class="py-2 float-right" style="float: right;">{{ selectedDetail.name }}</td>
                        </tr>
                    </template>
                    <template v-else-if="selectedDetailType === 'contacts'">
                        <tr>
                            <th class="py-2 float-left">Title</th>
                            <td class="py-2 float-right">{{ selectedDetail.title }}</td>
                        </tr>
                        <tr>
                            <th class="py-2 float-left">Name</th>
                            <td class="py-2 float-right">{{ selectedDetail.name }}</td>
                        </tr>
                        <tr>
                            <th class="py-2 float-left">Email</th>
                            <td class="py-2 float-right">{{ selectedDetail.email }}</td>
                        </tr>
                        <tr>
                            <th class="py-2 float-left">Phone</th>
                            <td class="py-2 float-right">{{ selectedDetail.phone }}</td>
                        </tr>
                        <tr>
                            <th class="py-2 float-left">Status</th>
                            <td class="py-2 float-right">{{ selectedDetail.status }}</td>
                        </tr>
                    </template>
                    <template v-else-if="selectedDetailType === 'notes'">
                        <tr>
                            <th class="py-2">Note Title</th>
                            <td class="py-2">{{ selectedDetail.title }}</td>
                        </tr>
                        <tr>
                            <th class="py-2">Content</th>
                            <td class="py-2">{{ selectedDetail.content }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>


            <!-- Action button to close details -->
            <div class="flex justify-end mt-4">
                <button @click="closeDetails"
                    class="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                    Close Details
                </button>
            </div>
        </div>



        <div class="flex justify-end space-x-4">
            <!-- Contract Update Form -->
            <div v-if="showUpdateContractForm" class="bg-white p-6 rounded-md shadow-md max-w-md mx-auto mt-4 mb-20">
                <form @submit.prevent="updateContract">
                    <!-- Fields for contract creation -->

                    <div class="mb-4">
                        <label for="contractNumber" class="block text-sm font-bold text-gray-700">Contract
                            Number:</label>

                        <div class="py-2">{{ updatedContract.contract_number }}</div>
                    </div>

                    <div class="mb-4">
                        <label for="contractStartDate" class="block text-sm font-bold text-gray-700">Start Date:</label>
                        <input type="date" id="contractStartDate" v-model="updatedContract.term_start_date"
                            @input="(e) => { updatedContract.term_start_date = e.target.value }"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact start date" />
                    </div>

                    <div class="mb-4">
                        <label for="contractEndDate" class="block text-sm font-bold text-gray-700">End Date:</label>
                        <input type="date" id="contractEndDate" v-model="updatedContract.term_end_date"
                            @input="(e) => { updatedContract.term_end_date = e.target.value }"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact start date" />
                    </div>

                    <div class="mt-4" v-if="contractUpdateError">
                        <p class="text-lg font-bold text-red-600">
                            {{ contractUpdateError }}</p>
                    </div>

                    <button type="submit"
                        class="bg-green-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                        Update Contract
                    </button>

                    <button @click="closeDetails"
                        class="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                        Close Details
                    </button>
                </form>
            </div>1
        </div>

        <!-- Create Detail Form Section -->
        <div class="flex justify-end space-x-4">
            <!-- Alias Creation Form -->
            <div v-if="showAliasForm" class="creation-form bg-white p-6 rounded-md shadow-md max-w-md mx-auto mt-4">
              <form @submit.prevent="createAlias">
                <div class="mb-4">
                  <label for="aliasName" class="block text-sm font-bold text-gray-700">Alias Name:</label>
                  <div>
                    <input type="text" id="aliasName" v-model="newAliasName" @input="validateInput(newAliasName)"
                           class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                           placeholder="Enter new alias name"/>
                    <p v-if="inputError" class="text-red-500">{{ inputError }}</p>
                  </div>
                </div>

                <div class="mt-4" v-if="newSchoolRelatedInfoCreationError">
                  <p class="text-lg font-bold text-red-600">
                    {{ newSchoolRelatedInfoCreationError }}</p>
                </div>

                <button type="submit" :disabled="inputError !== ''"
                        :class="{'bg-gray-400 cursor-not-allowed': inputError !== '', 'bg-green-500 hover:bg-green-600': inputError === ''}"
                        class="mt-4 text-white py-2 px-6 rounded-md focus:outline-none focus:bg-green-600">
                  Create Alias
                </button>

                <button
                    class="mt-4 bg-red-500 text-white py-2 px-6 rounded-md hover:bg-green-600 focus:outline-none focus:bg-red-600"
                    @click="closeSchoolDetails">
                  Close
                </button>

              </form>
            </div>

            <!-- Reporting Stream Creation Form -->
            <div v-if="showReportingStreamForm"
                class="creation-form creation-form bg-white p-6 rounded-md shadow-md max-w-md mx-auto mt-4">
                <form @submit.prevent="createReportingStream">
                    <!-- Fields for reporting stream creation -->
                    <label for="reportingStream" class="block text-sm font-bold text-gray-700">Reporting Stream
                      Name:</label>
                    <div class="relative">
                      <input type="text" id="reportingStreamName" v-model="newReportingStreamName" @input="validateInput(newReportingStreamName)"
                             class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                             placeholder="Enter new reporting stream name"/>
                      <p v-if="inputError" class="text-red-500">{{ inputError }}</p>
                    </div>



                  <div class="mt-4" v-if="newSchoolRelatedInfoCreationError">
                        <p class="text-lg font-bold text-red-600">
                            {{ newSchoolRelatedInfoCreationError }}</p>
                    </div>

                    <button type="submit" :disabled="inputError !== ''"
                            :class="{'bg-gray-400 cursor-not-allowed': inputError !== '', 'bg-green-500 hover:bg-green-600': inputError === ''}"
                            class="mt-4 text-white py-2 px-6 rounded-md focus:outline-none focus:bg-green-600">
                      Create Reporting Stream
                    </button>

                    <button
                        class="mt-4 bg-red-500 text-white py-2 px-6 rounded-md hover:bg-green-600 focus:outline-none focus:bg-red-600"
                        @click="closeSchoolDetails">
                        Close
                    </button>
                </form>
            </div>


            <!-- Contact Creation Form -->
            <div v-if="showContactForm" class="creation-form">
                <form @submit.prevent="createContact">
                    <div class="mb-4">
                        <label for="contactTitle" class="block text-sm font-bold text-gray-700">Title:</label>
                        <input type="text" id="contactTitle" v-model="newContact.title"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact title" />
                    </div>

                    <div class="mb-4">
                        <label for="contactName" class="block text-sm font-bold text-gray-700">Name:</label>
                        <input type="text" id="contactName" v-model="newContact.name"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact name" />
                    </div>

                    <div class="mb-4">
                        <label for="contactEmail" class="block text-sm font-bold text-gray-700">Email:</label>
                        <input type="email" id="contactEmail" v-model="newContact.email"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact email" />
                    </div>

                    <div class="mb-4">
                        <label for="contactPhone" class="block text-sm font-bold text-gray-700">Phone:</label>
                        <input type="text" id="contactPhone" v-model="newContact.phone"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact phone" />
                    </div>

                    <button type="submit" class="create-button">Create Contact</button>
                </form>
            </div>

            <!-- Contract Creation Form -->
            <div v-if="showContractForm" class="bg-white p-6 rounded-md shadow-md max-w-md mx-auto mt-4 mb-20">
                <form @submit.prevent="createContract">
                    <!-- Fields for contract creation -->

                    <div class="mb-4">
                        <label for="contractNumber" class="block text-sm font-bold text-gray-700">Contract
                            Number:</label>
                        <input type="text" id="contractNumber" v-model="newContract.contract_number"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter Contract Number" />
                    </div>

                    <div class="mb-4">
                        <label for="contractStartDate" class="block text-sm font-bold text-gray-700">Start Date:</label>
                        <input type="date" id="contractStartDate" v-model="newContractStartDate"
                            ref="contractStartDateInput"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact start date" />
                    </div>

                    <div class="mb-4">
                        <label for="contractEndDate" class="block text-sm font-bold text-gray-700">End Date:</label>
                        <input type="date" id="contractEndDate" v-model="newContractEndDate" ref="contractEndDateInput"
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Enter contact start date" />
                    </div>

                    <div class="mt-4" v-if="contractCreationError">
                        <p class="text-lg font-bold text-red-600">
                            {{ contractCreationError }}</p>
                    </div>

                    <button type="submit"
                        class="mt-4 bg-green-500 text-white py-2 px-6 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600">
                        Create Contract
                    </button>

                    <button @click="closeContractForm"
                        class="mt-4 bg-red-500 text-white py-2 px-6 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
                        Close
                    </button>
                </form>
            </div>

            <!-- Note Creation Form -->
            <div v-if="showNoteForm" class="creation-form">
                <form @submit.prevent="createNote">
                    <!-- Fields for note creation -->

                    <button type="submit" class="create-button">Create Note</button>
                </form>
            </div>


        </div>


        <div v-if="showCreateSchoolForm" class="bg-white p-6 rounded-md shadow-md max-w-md mx-auto mt-4 mb-20">
            <form @submit.prevent="createSchool">
                <label for="newSchool" class="block text-gray-700 text-sm font-bold mb-2">New School Name:</label>
                <div class="relative">
                    <input type="text" id="newSchool" v-model="newSchoolName" @input="validateInput(newSchoolName)"
                        class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        placeholder="Enter new school name" />
                    <p v-if="inputError" class="text-red-500"> {{ inputError }} </p>
                </div>

                <div class="mt-4" v-if="newSchoolCreateMessage">
                    <p class="text-lg font-bold"
                        :class="{ 'text-green-600': isCreationSuccess, 'text-red-600': !isCreationSuccess }">
                        {{ newSchoolCreateMessage }}</p>
                </div>

                <button type="submit" :disabled="inputError !== ''"
                        :class="{'bg-gray-400 cursor-not-allowed': inputError !== '', 'bg-green-500 hover:bg-green-600': inputError === ''}"
                        class="mt-4 text-white py-2 px-6 rounded-md focus:outline-none focus:bg-green-600">
                  Create School
                </button>

                <button @click="closeCreateSchoolForm"
                    class="mt-4 bg-red-500 text-white py-2 px-6 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
                    Close
                </button>
            </form>


        </div>
    </div>
</template>

<script>
import axios from "axios";
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";
import { ref } from 'vue';

const contractStartDateInput = ref(null);
const contractEndDateInput = ref(null);

export default {
    props: {
        showSchoolsTab: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            schools: [],
            schoolNames: [],
            apiUrl: process.env.VUE_APP_API_BASE_URL,
            selectedSchool: null,
            newSchoolName: '',
            inputError: '',
            showCreateSchoolForm: true,
            isCreationSuccess: false,
            newSchoolCreateMessage: '',
            newSchoolRelatedInfoCreationError: '',
            selectedDetail: null,
            selectedDetailType: '',
            showCreateDetailForm: false,
            newAliasName: '',
            newReportingStreamName: '',
            newContactName: '',
            showAliasForm: false,
            showReportingStreamForm: false,
            showContactForm: false,
            showContractForm: false,
            showUpdateContractForm: false,
            showNoteForm: false,
            selectedSchoolId: null,

            contractCreationError: null,
            contractUpdateError: null,
            newContractStartDate: '',
            newContractEndDate: '',
            newContact: {
                title: '',
                name: '',
                email: '',
                phone: '',
            },
            updatedContract: {
                id: null,
                school_id: null,
                term_start_date: '',
                term_end_date: '',
            },
            newContract: {
                contract_number: '',
                term_start_date: '',
                term_end_date: '',
                school_id: null,
            },

            newNote: {
                title: '',
                content: '',
            },
        };
    },
    mounted() {
        this.getSchoolNames();
        this.getSchoolDetails();
    },
    methods: {
        validateInput($input) {
          console.log($input);
          const pattern = /[^A-Za-z0-9-_() ]/;
          if (pattern.test($input)) {
            this.inputError = 'Only letters, numbers, and special characters -_() are allowed';
          } else {
            this.inputError = '';
          }

          console.log($input, this.inputError)
        },
        closeSchoolDetails() {
          this.showAliasForm = false;
          this.showReportingStreamForm = false;
          this.showContactForm = false;
          this.showContractForm = false;
          this.showNoteForm = false;
        },
        closeContractForm() {
            this.showContractForm = false;
            this.isCreationSuccess = false;
            this.clearContractForm();
        },
        closeCreateSchoolForm() {
            this.showCreateSchoolForm = false;
            this.isCreationSuccess = false;
            this.newSchoolCreateMessage = "";
            this.newSchoolName = "";
            this.inputError = "";
        },
        getApiConfig() {
            axios.defaults.withCredentials = true;
            axios.defaults.withXSRFToken = true;
            const token = this.$store.state.userToken;
            if (tokenService.isTokenExpired(token)) {
                AuthenticationService.logoutByTimeout(this.$store, this.$router);
                return false;
            }

            return {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
        },
        clearShowForms() {
            this.showAliasForm = false;
            this.showCreateSchoolForm = false;
            this.showReportingStreamForm = false;
            this.showContactForm = false;
            this.showContractForm = false;
            this.showNoteForm = false;
            this.showUpdateContractForm = false;
            this.selectedDetail = null;
            this.newSchoolRelatedInfoCreationError = null;
        },
        clearContractForm() {
            console.log(this.newContract)
            this.contractCreationError = null;
            this.contractUpdateError = null;
            this.showContractForm = false;
            this.newContract = {
                school_id: null,
                contract_number: '',
                term_start_date: '',
                term_end_date: '',
            }

            if (contractEndDateInput?.value) {
                contractEndDateInput.value.value = '';
            } else {
                this.newContractEndDate = ''
            }


            if (contractStartDateInput?.value) {
                contractStartDateInput.value.value = '';
            } else {
                this.newContractStartDate = ''
            }
        },
        showContactCreationForm(schoolId) {
            this.clearShowForms();
            this.showContactForm = true;
            this.selectedSchoolId = schoolId;
        },
        showAliasCreationForm(schoolId) {
            this.clearShowForms();
            this.showAliasForm = true;
            this.selectedSchoolId = schoolId;
            this.inputError = '';
            this.newAliasName = '';
        },
        showReportingStreamCreationForm(schoolId) {
            this.clearShowForms();
            this.showReportingStreamForm = true;
            this.selectedSchoolId = schoolId;
            this.inputError = '';
            this.newReportingStreamName = '';
        },
        showContractCreationForm(schoolId) {
            this.clearShowForms();
            this.showContractForm = true;
            this.newContract.school_id = schoolId;
        },
        showSchoolCreationForm() {
            this.clearShowForms();
            this.showCreateSchoolForm = true;
            this.inputError = '';
            this.newSchoolName = '';
        },

        async updateContract() {
            if (!this.getApiConfig()) {
                return;
            }

            const config = this.getApiConfig();
            const updatedContract = {
                ...this.updatedContract
            };

            await axios.put(this.apiUrl + '/api/contracts/' + updatedContract.id, updatedContract, config)
                .then(() => {
                    this.clearContractForm();
                    this.getSchoolDetails();
                    this.closeDetails();
                })
                .catch((error) => {
                    const err = error.response?.data?.message
                    this.contractUpdateError = err
                });
        },
        async createContract() {
            if (!this.getApiConfig()) {
                return;
            }

            const config = this.getApiConfig();

            const contract = {
                ...this.newContract,
                term_start_date: this.newContractStartDate,
                term_end_date: this.newContractEndDate,
            }

            await axios.post(this.apiUrl + '/api/contracts', contract, config)
                .then(() => {
                    this.clearContractForm();
                    this.getSchoolDetails();
                })
                .catch((error) => {
                    const err = error.response?.data?.message
                    this.contractCreationError = err
                });
        },
        async createAlias() {
            if (!this.getApiConfig()) {
                return;
            }

            const config = this.getApiConfig();
            await axios.post(this.apiUrl + '/api/aliases', {
                name: this.newAliasName,
                school_id: this.selectedSchoolId,
            }, config)
                .then(() => {
                    this.newAliasName = '';
                    this.showAliasForm = false;
                    this.getSchoolDetails();
                })
                .catch((error) => {
                    console.error('Error creating new alias!', error);
                    this.newSchoolRelatedInfoCreationError = error.response.data.error;
                });
        },
        async createReportingStream() {
            if (!this.getApiConfig()) {
                return;
            }

            const config = this.getApiConfig();
            await axios.post(this.apiUrl + '/api/reportingStreams', {
                name: this.newReportingStreamName,
                school_id: this.selectedSchoolId,
            }, config)
                .then(() => {
                    this.newReportingStreamName = '';
                    this.showReportingStreamForm = false;
                    this.getSchoolDetails();
                })
                .catch((error) => {
                    console.error('Error creating new reporting stream!', error);
                    this.newSchoolRelatedInfoCreationError = error.response.data.error;
                });
        },
        async createContact() {
            const token = this.$store.state.userToken;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios.defaults.withCredentials = true;

            await axios.post(this.apiUrl + '/api/contacts', {
                title: this.newContact.title,
                name: this.newContact.name,
                email: this.newContact.email,
                phone: this.newContact.phone,
                status: 'active',
                school_id: this.selectedSchoolId,
            }, config)
                .then(() => {
                    this.newContact = {
                        title: '',
                        name: '',
                        email: '',
                        phone: '',
                    };
                    this.showContactForm = false;
                    this.getSchoolDetails();
                })
                .catch((error) => {
                    console.error('Error creating new contact!', error);
                });
        },
        // Method to reset the form fields and hide the form
        resetCreateDetailForm() {
            this.newAliasName = ''; // Reset other form fields
            this.showCreateDetailForm = false;
        },
        async createSchool() {
            const token = this.$store.state.userToken;
            //check if token expired
            if (tokenService.isTokenExpired(token)) {
                AuthenticationService.logoutByTimeout(this.$store, this.$router);
                return;
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            axios.defaults.withCredentials = true;

            await axios.post(this.apiUrl + '/api/schools', { name: this.newSchoolName }, config)
                .then(response => {
                    this.isCreationSuccess = true;
                    this.newSchoolCreateMessage = response.data.message;
                    this.newSchoolName = '';
                    this.getSchoolNames();
                    this.getSchoolDetails();
                })
                .catch(error => {
                    this.isCreationSuccess = false;
                    this.newSchoolCreateMessage = error?.response?.data?.message;
                });
        },
        async getSchoolNames() {
            const token = this.$store.state.userToken;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await axios.get(this.apiUrl + '/api/schools', config)
                .then(response => {
                    this.schoolNames = response.data;
                })
                .catch(error => {
                    console.error('Error loading School Names!', error);
                });
        },
        showSchoolDetails(school) {
            this.selectedSchool = school;

        },
        async getSchoolDetails() {
            const token = this.$store.state.userToken;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await axios.get(this.apiUrl + '/api/schools/details', config)
                .then(response => {
                    this.schools = response.data;
                })
                .catch(error => {
                    console.error('Error loading School Names!', error);
                });
        },
        async showAliasDetails(alias) {
            this.selectedDetail = alias;
            this.selectedDetailType = 'aliases';
        },
        async showReportingStreamDetails(reportingStream) {
            this.selectedDetail = reportingStream;
            this.selectedDetailType = 'reporting_streams';
        },
        async showContactDetails(contact) {
            const contactId = contact.id;
            const token = this.$store.state.userToken;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await axios.get(this.apiUrl + `/api/contacts/${contactId}`, config)
                .then(response => {
                    this.selectedDetail = response.data;
                    this.selectedDetailType = 'contacts';
                })
                .catch(error => {
                    console.error('Error loading Contact Details!', error);
                });
        },
        async showContractDetails(contract) {
            this.clearShowForms();
            this.showUpdateContractForm = true;
            this.updatedContract = { ...contract }
        },
        async showNoteDetails(note) {
            this.selectedDetail = note;
            const noteId = note.id;
            const token = this.$store.state.userToken;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await axios.get(this.apiUrl + `/api/notes/${noteId}`, config)
                .then(response => {
                    this.selectedDetail = response.data;
                    this.selectedDetailType = 'notes';
                })
                .catch(error => {
                    console.error('Error loading Note Details!', error);
                });
        },
        async deleteDetails() {
            const detailId = this.selectedDetail.id;
            if (!this.getApiConfig()) {
                return;
            }

            const config = this.getApiConfig();
            await axios.delete(this.apiUrl + `/api/${this.selectedDetailType}/${detailId}`, config)
                .then(() => {
                    this.selectedDetail = null;
                    this.getSchoolDetails();
                })
                .catch((error) => {
                    console.error('Error deleting detail!', error);
                });
        },
        closeDetails() {
            this.selectedDetail = null;
            this.showUpdateContractForm = false;
        },
    },
}

</script>

<style scoped>
.table {
    width: 100%;
    border-collapse: collapse;
}

.table li {
    border-bottom: 1px solid #e2e8f0;
    padding: 8px;
    text-align: center;
}



.table li:hover {
    background-color: #cbd5e0;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    border-bottom: 1px solid #e2e8f0;
    padding: 8px;
    text-align: center;
}

.table th {
    background-color: #f7fafc;
}



.table tr:hover {
    background-color: #cbd5e0;
}

.create-button {
    background-color: #48bb78;
    /* Green color */
    color: #fff;
    /* White text */
    border: none;
    padding: 6px 10px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #38a169;
    /* Darker green color on hover */
}

.action-button {}

.creation-form {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.create-button,
.delete-button {
    color: #fff;
    padding: 0.5rem 1rem;
    /* Adjust padding to ensure same height */
    border-radius: 0.75rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-button {
    background-color: #4caf50;
}

.delete-button {
    background-color: #f44336;
}

.create-button:hover {
    background-color: #45a049;
}

.delete-button:hover {
    background-color: #da190b;
}
</style>
