<template>
  <div v-if="showUploadTab" class="flex items-center justify-center bg-gray-100 mt-12 mb-6">
    <div class="bg-white p-8 rounded shadow-md mt-6 mb-6">
      <h1 class="text-2xl font-bold mb-4">Student CSV File Uploader</h1>

      <div class="mb-6">
        <h2 class="text-xl font-bold mb-2">File Upload Guidance:</h2>
        <ul class="list-none">
          <li>1. Make sure your file format is CSV, not Excel or any other type.</li>
          <li>2. Column headers should be the exact same spelling and order as follows:
            Student Identifier, School Name, Reporting Stream Name, Cancel (optional)
            <ul class="list-disc ml-6">
              <li>To cancel a student's registration, put "yes" in the Cancel column for that student.
              </li>
            </ul>
          </li>
          <li>3. Ensure there is only one row per student, per school.</li>
          <li>4. Duplicate records will be stored only once.
            If student information is changed in subsequent uploads, only the last uploaded data for that
            student will
            be saved.
          </li>
        </ul>

        <div class="mt-4 border-2 w-3/5 p-1.5 mx-auto flex items-center ml-8">
                    <span>
                        <input type="checkbox" v-model="consent" id="consent" name="consent"
                               @change="handleConsentChange" class="checkbox mr-2">
                    </span>
          <span>
                        <label for="consent" class="">I hereby declare, on behalf of my organization, that we have
                            obtained the
                            consent of our registrants to provide their personal information to guard.me International
                            Insurance, for
                            the purposes of enrolling them in the Digital Doctor program.</label>
                    </span>

        </div>
      </div>

      <div class="flex items-end">

        <div class="flex flex-col " v-if="contracts?.length">
          <label for="contracts">Contract Term</label>
          <select id="contracts" class="mr-2" aria-label="Available Contract" @change="handleSelectContract">
            <option v-for="option in contracts" :value="option.id" :key="option.id"
                    :selected="option.id == selectedContract?.id ? 'true' : 'false'">
              {{ option.term_start_date_formatted }} - {{ option.term_end_date_formatted }}
            </option>
          </select>

        </div>

        <input type="file" id="file" ref="fileInput" @change="handleFileChange"
               class="mr-2 p-1.5 border border-gray-300"/>

        <button @click="uploadFile" :disabled='disableUploadButton'
                :class="{ 'cursor-not-allowed': disableUploadButton }"
                class="bg-blue-500 text-white p-2.5 rounded hover:bg-blue-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed">
          Upload CSV
        </button>

      </div>

      <div v-if="emptyContractsError" class="red-100 text-red-700 p-2 rounded">
        <p>{{ emptyContractsError }}</p>
      </div>


      <div v-if="uploadStatus === 'uploading'" class="mb-4">
        <p class="font-bold">File Upload in Progress...</p>
      </div>

      <div v-if="uploadStatus === 'success'" class="text-green-500 font-bold mb-4">
        <p>
          Your file uploaded successfully!
        </p>
      </div>
      <div v-if="uploadStatus === 'failed'"
           class="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded">
        <p>File Upload Failed!</p>
        <p> {{ fileUploadError }} </p>
      </div>

      <div v-if="validateStatus === 'validating'" class="mb-4">
        <p class="font-bold">File Validating in Progress...</p>
      </div>

      <div v-if="validateStatus === 'success'" class="font-bold mb-4">
        <p>
          Your file will be processed and you will be notified via email upon completion.
        </p>
        <p>
          If you don't receive an email, contact your GME Account Manager.
        </p>
      </div>
      <div v-if="validateStatus === 'failed'"
           class="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded">
        <p>We're sorry, we're having trouble processing your file. Please try again after few minutes or reach
          out to your Account Manager for help. Thank you and sorry for the inconvenience!</p>
      </div>

      <div v-if="validateStatus === 'failed'">
        <error-summary :errors="errorSummary"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Vapor from 'laravel-vapor'
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";
import ErrorSummary from "@/components/DDErrorSummary.vue";

export default {
  props: {
    showUploadTab: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    ErrorSummary
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      file: null,
      guardMeFileId: null,
      fileUploadError: "",
      fileValidationError: "",
      uploadStatus: '',
      uploadProgress: 0,
      validateStatus: '',
      validateProgress: 0,
      processStatus: '',
      processProgress: 0,
      errorSummary: null,
      consent: false,
      selectedContract: null,
      emptyContractsError: null,
      contracts: [],
    };
  },
  mounted() {
    this.getContracts()
  },
  computed: {
    disableUploadButton: function () {
      return !this.file || !this.consent || !this.selectedContract?.id
    }
  },
  methods: {
    getApiConfig() {
      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logoutByTimeout(this.$store, this.$router);
        return false;
      }

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    },

    handleSelectContract(e) {
      const val = e.target.value;
      this.selectedContract = this.contracts.find((e) => e.id == val);
    },
    getContracts() {
      const config = this.getApiConfig();

      if (!config) {
        return false;
      }

      axios.get(this.apiUrl + '/api/contracts/list', config).then((response) => {
        this.contracts = response.data.contracts;
        this.selectedContract = response.data.active ?? this.contracts[0] ?? null;
        if (!this.selectedContract) {
          this.emptyContractsError = response.data.error;
        }
      }).catch((error) => {
        console.error(
            "Erorr getting contracts",
            error
        )
      });
    },

    checkFileType() {
      if (this.file.type !== 'text/csv') {
        this.fileUploadError = 'Invalid file type. Please upload a CSV file.';
        this.uploadStatus = 'failed';
        return false;
      }

      return true;
    },
    handleFileChange(event) {
      if (event.target.files[0] == null) {
        this.file = null;
        return;
      }

      this.file = event.target.files[0];
      this.fileUploadError = '';
      this.uploadStatus = '';
      this.processStatus = '';
      this.validateStatus = '';
    },
    async uploadFile() {
      // clear status
      this.processStatus = '';
      this.uploadStatus = '';
      this.validateStatus = '';

      //clear all messages
      this.fileValidationError = '';
      this.fileUploadError = '';

      //clear error summary
      this.errorSummary = null;

      this.uploadStatus = 'uploading';
      if (!this.checkFileType()) {
        return;
      }

      if (this.consent === false) {
        this.processStatus = 'failed';
        this.fileUploadError = 'Please check consent to upload the file.';
        return;
      }

      const token = this.$store.state.userToken;
      const $store = this.$store;
      const $router = this.$router;

      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logoutByTimeout(this.$store, this.$router);
        return;
      }


      const options = {
        baseURL: this.apiUrl,
        signedStorageUrl: '/vapor/signed-storage-url',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        progress: progress => {
          this.uploadProgress = Math.round(progress * 100);
        }
      };

      axios.defaults.withCredentials = true;
      // axios.defaults.withXSRFToken = true;
      // Use Vapor.store to upload the file directly to S3
      await Vapor.store(
          this.file,
          options
      ).then(
          async (response) => {
            const token = this.$store.state.userToken;
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

            axios.defaults.withXSRFToken = true;
            await axios.post(this.apiUrl + '/api/files/upload-s3', {
              name: this.file.name,
              uuid: response.uuid,
              bucket: response.bucket,
              key: response.key,
              url: response.url,
              contract_id: this.selectedContract.id,
            }, config).then((response) => {
              this.guardMeFileId = response.data.id;
              this.uploadStatus = 'success';

              //clear file
              this.$refs.fileInput.value = '';
              this.file = null;

            }).catch((error) => {
              console.error('Failed to upload file, Error:', error);
              this.uploadStatus = 'failed';
              this.fileUploadError = error.response?.data?.message;
            });

            this.$refs.fileInput.value = '';
          })
          .catch(
              error => {
                console.error('Failed to upload file, Error:', error);
                this.uploadStatus = 'failed';

                //if 403, redirect to login
                if (error.response?.status === 401) {
                  console.error('Token expired');
                  this.$store.commit('setUserToken', '')
                  this.$store.commit('setUserName', '');
                  this.$store.commit('setShowTokenExpiringMessage', true);
                  this.$router.push('/login');
                  return AuthenticationService.logoutByTimeout($store, $router);
                }

                if (error.response?.status == 403
                    && error?.response?.data?.message == 'User access is disabled by the admin') {
                  return AuthenticationService.disable($store, $router);
                }

                if (error.response && error.response.data) {
                  console.log(error.response.data.message);
                } else if (error?.message) {
                  console.log(error.message);
                } else {
                  this.fileUploadError = "We're sorry, we're having trouble processing your file. Please try again after few minutes or reach out to your Account Manager for help. Thank you and sorry for the inconvenience!";
                }
              }
          )
          .finally(() => {
            this.consent = false;
          });

      if (this.uploadStatus === 'success') {
        await this.validateFile();
      }
    },
    async validateFile() {
      this.validateStatus = 'validating';
      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      const token = this.$store.state.userToken;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };
      await axios
          .post(this.apiUrl + '/api/files/validate', {
            fileId: this.guardMeFileId,
          }, config)
          .then(response => {
            if (response.status === 200) {
              this.fileValidationStatus = true;
              this.validateStatus = 'success';
            } else if (response.status === 422) {
              this.fileValidationStatus = false;
              this.fileValidationError = response.data.errors[0];
              this.errorSummary = response.data.errors;
              this.validateStatus = 'failed';
            } else {
              this.fileValidationStatus = false;
              this.fileValidationError = "We're sorry, we're having trouble processing your file. Please try again after few minutes or reach out to your Account Manager for help. Thank you and sorry for the inconvenience!";
              this.validateStatus = 'failed';
            }
          })
          .catch(error => {
            this.validateStatus = 'failed';
            if (error.response.status === 422) {
              this.fileValidationStatus = false;
              this.fileValidationError = error.response.data.errors[0];
              this.errorSummary = error.response.data.errors;
            } else if (error.response.status === 500) {
              this.validateStatus = 'error';
              this.fileValidationError = "We're sorry, we're having trouble processing your file. Please try again after few minutes or reach out to your Account Manager for help. Thank you and sorry for the inconvenience!";

            } else {
              this.fileValidationStatus = false;
              this.fileValidationError = "We're sorry, we're having trouble processing your file. Please try again after few minutes or reach out to your Account Manager for help. Thank you and sorry for the inconvenience!";

            }
          });
    },
    handleConsentChange(event) {
      // disable upload button is decided by watcher disableUploadButton
      this.consent = event.target.checked;
    }
  },
};
</script>

<style scoped>
.checkbox {
  margin-bottom: 22px;
}
</style>
