<template>
  <div v-if="showApiTab" class="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
    <div class="mb-4">
      <label for="studentId" class="block text-gray-700 text-sm font-bold mb-2">
        Student Identifier:
      </label>
      <input
          type="text"
          id="studentId"
          v-model="studentId"
          @focus="showTooltip = true"
          @blur="showTooltip = false"
          @input="updateLength"
          class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
      />

      <div
          v-if="showTooltip"
          class=" bg-gray-100 border border-gray-300 p-2 rounded-md mb-3"
          style="top: 100%; left: 0; transform: translateY(10px);"
      >
        Current Length: {{ studentIdLength }}
      </div>

      <label for="schoolDropdown" class="block text-gray-700 text-sm font-bold mb-2">School:</label>
      <div>
        <select v-model="schoolNameSelected" @change="onSchoolChange" id="schoolDropdown">
          <option v-for="(schoolName, index) in schoolNames" :key="index">
            {{ schoolName }}
          </option>
        </select>
      </div>

      <div v-if="lengths && lengths.length > 0">
        <label>
          Student Identifier Lengths:
        </label>
        <ul>
          <li v-for="(schoolName, index) in lengths" :key="index">
            {{ schoolName }}
          </li>
        </ul>
      </div>

      <div class="mb-6">
        <button
            @click="checkStudent"
            :disabled="disableSubmit()"
            class="w-full bg-blue-500 text-white py-2 mt-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Submit
        </button>
      </div>

      <h2 class="text-lg font-bold mb-2 text-green-600" v-if="eligible"> Status: Eligible </h2>
      <div v-if="error !== null" class="text-red-600">
        <h2 class="text-lg font-bold mb-2"> Status: Not Eligible </h2>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";

export default {
  props: {
    showApiTab: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      studentId: "",
      schoolNameSelected: "",
      schoolNames: [],
      eligible: null,
      error: null,
      lengths: [],
      studentIdLength: 0,
      showTooltip: false,
    };
  },
  mounted() {
    this.getSchoolNames();
  },
  methods: {
    disableSubmit() {
      return this.studentIdLength === 0 || this.schoolNameSelected === "";
    },
    updateLength(event) {
      //clear messages
      this.error = null;
      this.eligible = null;
      this.studentIdLength = event.target.value.length;
    },
    async onSchoolChange() {
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        console.error('Token expired');
        AuthenticationService.logoutByTimeout(this.$store, this.$router);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      ///api/schools/{id}/lengths
      await axios.post(this.apiUrl + "/api/schools/lengths", {
        schoolName: this.schoolNameSelected,
      }, config)
          .then(response => {
            this.lengths = response.data.lengths;
          })
          .catch(error => {
            console.error('Error loading Lengths: ' + error.response.data.message);
          });
    },
    async getSchoolNames() {
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logoutByTimeout(this.$store, this.$router);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.get(this.apiUrl + '/api/schools', config)
          .then(response => {
            this.schoolNames = response.data.schools;
          })
          .catch(error => {
            console.error('Error loading School Names: ' + error.response.data.message);
          });
    },
    async checkStudent() {
      this.eligible = null;
      this.error = null;
      // axios.defaults.withXSRFToken = true;
      axios.defaults.withCredentials = true;

      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logoutByTimeout(this.$store, this.$router);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
          .post(this.apiUrl + "/api/check-student", {
            studentIdentifier: this.studentId,
            schoolName: this.schoolNameSelected,
          }, config)
          .then((response) => {
            this.eligible = response.data.eligible;
            if (!this.eligible) {
              this.error = response.data.message;
            }
          })
          .catch((error) => {
            this.error = error.response.data.message;
          });
    },
  },
};
</script>

<style scoped>

</style>
