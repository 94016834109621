<template>
  <div class="w-full max-w-sm mx-auto mt-8 bg-white p-6 rounded-md shadow-md">
    <h2 class="text-2xl font-bold mb-4">Reset Password</h2>

    <!-- Form for resetting password -->
    <form @submit.prevent="resetPassword">
      <div class="mb-4">
        <label for="current_password" class="block text-gray-700">Current Password:</label>
        <input
            type="password"
            id="current_password"
            v-model="form.current_password"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            required
        />
      </div>

      <div class="mb-4">
        <label for="new_password" class="block text-gray-700">New Password:</label>
        <input
            type="password"
            id="new_password"
            v-model="form.new_password"
            @input="validatePassword()"
            @focus="showTooltip = true"
            @blur="showTooltip = false; validatePassword()"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            required
        />
      </div>

      <div v-if="showTooltip" class="absolute bg-white border border-gray-300 p-2 rounded shadow-md text-sm z-10 ">
        <ul class="text-gray-600">
          <li :class="{ 'text-green-600': validLength }">At least 8 characters</li>
          <li :class="{ 'text-green-600': hasUppercase }">At least 1 uppercase letter</li>
          <li :class="{ 'text-green-600': hasLowercase }">At least 1 lowercase letter</li>
          <li :class="{ 'text-green-600': hasSpecialCharacter }">At least 1 special character  (@$!%*#?&)</li>
          <li :class="{ 'text-green-600': hasNumeric }">At least 1 numeric character</li>
        </ul>
      </div>

      <div class="mb-4">
        <label for="new_password_confirmation" class="block text-gray-700">Confirm New Password:</label>
        <input
            type="password"
            id="new_password_confirmation"
            v-model="form.new_password_confirmation"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            required
        />
      </div>

      <div class="mt-6">
        <button
            type="submit"
            :disabled="!isPasswordValid"
            class="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Reset Password
        </button>
      </div>
    </form>

    <!-- Error or Success Messages -->
    <div v-if="message" class="mt-4">
      <p :class="{ 'text-green-600': success, 'text-red-600': !success }">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      form: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      message: '',
      success: false,
      showTooltip: false,
      validLength: false,
      hasUppercase: false,
      hasLowercase: false,
      hasSpecialCharacter: false,
      hasNumeric: false,
    };
  },
  computed: {
    isPasswordValid() {
      return (
          this.validLength &&
          this.hasUppercase &&
          this.hasLowercase &&
          this.hasSpecialCharacter &&
          this.hasNumeric
      );
    },
  },
  methods: {
    getApiConfig() {
      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logoutByTimeout(this.$store, this.$router);
        return false;
      }
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    },
    async resetPassword() {
      if (this.form.new_password !== this.form.new_password_confirmation) {
        this.message = "New passwords don't match!";
        this.success = false;
      } else {
        if (!this.getApiConfig()) {
          return;
        }

        // Call the API to reset the password
        const config = this.getApiConfig();
        await axios.patch(this.apiUrl + '/api/cognito/passwords/change', this.form, config)
            .then(() => {
              this.message = 'Password reset successfully!';
              this.success = true;
            })
            .catch((error) => {
              console.error('There was an error!', error);
              this.message = error.response.data.message;
              this.success = false;
            });
      }
    },
    validatePassword() {
      const password = this.form.new_password;

      this.validLength = password.length >= 8;
      this.hasUppercase = /[A-Z]/.test(password);
      this.hasLowercase = /[a-z]/.test(password);
      this.hasSpecialCharacter = /[@$!%*#?&]/.test(password);
      this.hasNumeric = /\d/.test(password);
    },
  },
};
</script>

<style scoped>
</style>
