<template>
  <main>
    <div class="unauthenticatedContainer flex flex-col min-h-screen justify-between">
      <div class="flex-grow flex items-center justify-center">
        <slot></slot>
      </div>
      <div id="footer-bar" class="flex justify-between items-center px-16 py-2 text-sm">
        <span id="left-footer">
          &copy; 2024 GuardMe International Insurance. All rights reserved.
        </span>
        <span id="right-footer" class="flex space-x-4 text-primaryBlue hover:text-black">
          <router-link :to="{ path: '/public/privacy-notice' }" target="_blank">Privacy Notice</router-link>
          <router-link :to="{ path: '/public/unauthenticated/terms-of-use' }" target="_blank">Terms of Use</router-link>
          <router-link :to="{ path: '/public/unauthenticated/cookie-notice' }" target="_blank">Cookie Notice</router-link>
        </span>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'UnauthenticatedLayout',
};
</script>

<style scoped>

</style>